import React from 'react'

function Footer() {
  return (
    <footer class="text-gray-600 body-font">
  <div class="bg-gray-100">
    <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
      {<p class="text-gray-500 text-sm text-center sm:text-left">© 2024 — Litty Universal Solutions, LLC</p>}
      <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
        <a class="ml-3 text-gray-500" href='https://www.freeprivacypolicy.com/live/e9ab12d9-1c63-4b1e-beaa-35af5c909288'>
          Terms of Service
        </a>
        <a class="ml-3 text-gray-500" href='https://www.freeprivacypolicy.com/live/62cc92ea-0ead-4c6a-850d-4a2b7d824579'>
          Privacy Policy
        </a>
      </span>
    </div>
  </div>
</footer>
  )
}

export default Footer