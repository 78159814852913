import React, { useEffect, useState } from 'react'
import LayoutCard from './LayoutCard'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getTextColor } from '../../Utilities';

function LayoutsGrid() {
    const navigate = useNavigate()
    const [layouts, setLayouts] = useState([])

    const navigateTo = (id) => {
        navigate(`/layout/${id}`);
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        // fetch layout data
        // const baseUrl = "http://localhost:3000"; // Local
        // const baseUrl = "htp://192.168.1.206:3000"; // Accessing via device
        // const baseUrl = "https://test.curatelife.app"; // Test
        const baseUrl = "https://dev.curatelife.app"; // Dev
        const exploreLayoutsUrl = `${baseUrl}/api/app/explore/layouts?limit=12`;
        axios.get(exploreLayoutsUrl).then((res) => {
            const results = res.data.layouts.map((item) => (
                {
                    id: item.id,
                    name: item.name,
                    preview: item.preview,
                    username: item.user.username,
                    profilePhoto: item.user.profilePhoto,
                    backgroundColor: item.backgroundColor,
                    textColor: getTextColor(item.backgroundColor)
                }
            ));

        setLayouts(results);
        })
    },[])
    return (
        <div className='mt-2 md:mt-4 rounded-lg'>
        <h4 className='flex px-4 md:px-8 lg:px-12 font-polliSansBold text-xl md:text-x2l lg:text-3xl'>Explore Layouts</h4>
        <div className='mt-4 px-4 md:px-8 lg:px-12 grid grid-cols-1 sm:grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 rounded-lg'>{
                layouts.map((item)=>(
                    <div key={item.id} onClick={() => navigateTo(item.id)}>
                        <LayoutCard layout={item} />
                    </div>
                ))}
            </div>
        </div>
    )

}

export default LayoutsGrid

//onClick={()=>navigate('/'+item.id)}