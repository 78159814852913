import React, { useState } from 'react'
import axios from 'axios';
import AppStore from '../../Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';

function Main() {

  return (
    <div class="relative overflow-hidden">
      <div class="pt-10 pb-14 sm:pt-16 lg:overflow-hidden lg:pt-24 lg:pb-24">
        <div class="mx-auto max-w-5xl lg:px-8">
          <div class="lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="mx-auto max-w-md px-4 text-center sm:max-w-2xl sm:px-6 lg:flex lg:items-center lg:px-0 lg:text-left">
              <div class="lg:py-24">
                <h1 class="mt-4 text-6xl font-backGroove tracking-tight text-lightBlue sm:mt-5 sm:text-8xl lg:mt-6 md:text-8xl lg:text-9xl">Curate</h1>
                <h2 class="mt-4 text-4xl font-backGroove tracking-tight text-darkBlue sm:mt-5 sm:text-6xl lg:mt-6 md:text-6xl lg:text-7xl"><span class="inline-flex text-darkBlue">Your Space. Your Way.</span></h2>
                <p class="mt-3 text-base text-gray-800 sm:mt-5 sm:text-xl lg:text-2xl xl:text-3xl font-polliSansRegular">Exercise your creative side with Curate, the mobile design tool for the future of the internet.</p>
                <div class="flex justify-center items-center p-4">
                <img class="w-44" src={AppStore}/>
                </div>
              </div>
            </div>
            <div class="mt-12 md:inline-flex lg:block"><img src={require('../../screenshot.png')} alt="" /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main

// onChange={(e) => setEmail(e.target.value)}